import { HeadProps, Link } from 'gatsby'
import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import WidthLimiter from '../components/widthLimiter/widthLimiter'

const NotFoundPage = () => {
  return (
    <Layout>
      <WidthLimiter>
        <h1>Seite existiert nicht</h1>
        <p>
          Upps, die Seite existiert nicht. Traurig. Vermutlich wolltest du{' '}
          <Link to="/">hier</Link> hin.
        </p>
        <div
          style={{
            padding: '0 0 var(--spacing-32)',
            textAlign: 'center',
          }}
        >
          <StaticImage
            src="../images/cat.png"
            placeholder="blurred"
            width={500}
            formats={['auto', 'webp', 'avif']}
            alt="Verwirrte Katze"
            transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
          />
        </div>
      </WidthLimiter>
    </Layout>
  )
}

export const Head = ({ location }: HeadProps) => (
  <Seo
    title="404: Seite existiert nicht"
    noindex
    pathname={location.pathname}
  />
)

export default NotFoundPage
